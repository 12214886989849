import React from "react";
import { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Page } from "../layout/page";
import ThunderboltIcon from "../images/icon-thunderbolt.svg";
import ClockIcon from "../images/icon-clock-4.svg";
import MicroorganismIcon from "../images/icon-microorganism.svg";
import CalendarIcon from "../images/icon-calendar-check.svg";

import * as css from "./technology.module.scss";
import { HeaderMergedContent } from "../layout/header_merged_content";
import { Parallax } from "../layout/parallax";
import { AnimateIn } from "../layout/animate_in";

export default function IndexPage(props: PageProps) {
  return (
    <Page menuId="technology" title="Technology">
      <HeaderMergedContent>
        <div className={css.pageHead}>
          <h2>Technology</h2>
          <hr />
          <div className={css.intro}>
            First-in-Class Energy Efficient Manufacturing of Lipid-Based Sustained Release
            Transdermal Nano Drug Delivery Systems
          </div>
          <ul className={css.toc}>
            <li>
              <ThunderboltIcon className={css.icon} />
              Energy Efficient
            </li>
            <li>
              <ClockIcon className={css.icon} />
              Sustained Release
            </li>
            <li>
              <MicroorganismIcon className={css.icon} />
              Advanced Nano
            </li>
            <li>
              <CalendarIcon className={css.icon} />
              Longer Shelf Life
            </li>
          </ul>
        </div>
      </HeaderMergedContent>

      <AnimateIn className={css.section} direction="up">
        <div className={css.left}>
          <ThunderboltIcon className={css.icon} />
          <h3>Energy Efficient Production</h3>
          <div className={css.content}>
            Lowering production cost and Green House Gas Emissions (GHG)
            <p />
            CNano’s technology is cost-effective compared to high pressure homogenization techniques
            and results in reduced GHG emission and product waste.
          </div>
        </div>
        <div className={css.right}>
          <Parallax className={css.img}>
            <StaticImage src="../images/tech-b-energy.jpg" alt="" />
          </Parallax>
        </div>
      </AnimateIn>
      <AnimateIn className={css.section} direction="left">
        <div className={css.left}>
          <ClockIcon className={css.icon} />
          <h3>Sustained Release with a Rapid Onset of Action</h3>
          <div className={css.content}>
            Reducing the need for frequent dosing while making treatment easier and enhancing
            patient adherence.
            <p />
            We use a range of advanced drug delivery technologies and components, such as film
            forming polymers and charged lipids, to extend the active duration of dermal and
            transdermal medicines and optimize their stay at the their target skin layer.
            Additionally to provide immediate action of pain relief medicines, we utilize advanced
            technologies to achieve a balanced ratio of excipients, thereby allowing rapid release
            of medicine while sustaining the release for a longer period of time.
          </div>
        </div>
        <div className={css.right}>
          <Parallax className={css.img}>
            <StaticImage src="../images/tech-b-release.jpg" alt="" />
          </Parallax>
        </div>
      </AnimateIn>
      <AnimateIn className={css.section} direction="right">
        <div className={css.left}>
          <MicroorganismIcon className={css.icon} />
          <h3>
            Advanced Multiple Nano Emulsification Technology for the Dermal and Transdermal Delivery
            of Multiple Active Ingredients
          </h3>
          <div className={css.content}>
            Delivering various medicines at the same time with synergic therapeutic effect.
            <p />
            We employ intelligent drug delivery technologies to enhance the performance of active
            ingredients in skin delivery systems with opportunities for developing novel
            regenerative, analgesics, anti-aging, and anti-inflammatory medicines that overcome
            major technological and socioeconomic challenges associated with conventional delivery
            methods.
          </div>
        </div>
        <div className={css.right}>
          <Parallax className={css.img}>
            <StaticImage src="../images/tech-b-nano.jpg" alt="" />
          </Parallax>
        </div>
      </AnimateIn>
      <AnimateIn className={css.section} direction="left">
        <div className={css.left}>
          <CalendarIcon className={css.icon} />
          <h3>Robust Structure with Extended Shelf Life</h3>
          <div className={css.content}>
            Enabling development of robust and thermodynamically stable formulations for multiple
            active ingredients at a high concentration.
            <p />
            CNano’s breakthrough nanomedicinal delivery technology is intended and designed to be
            used for various active ingredients with both hydrophobic and hydrophilic structures,
            thereby serving as platform for accelerating the state of Canadian innovation ahead of
            global competitors across various industries such as pharmaceutical, biopharmaceutical,
            cosmeceutical, nutraceutical and Cannabis sectors.
          </div>
        </div>
        <div className={css.left}>
          <Parallax className={css.img}>
            <StaticImage src="../images/tech-b-shelf-life.jpg" alt="" />
          </Parallax>
        </div>
      </AnimateIn>
    </Page>
  );
}
